import React, {FC, useContext, useEffect, useState} from 'react';
import {Button, Card, Heading, HStack, Image, Text, useTheme, VStack, Box, combineContextAndProps} from 'native-base';
import {ICertificate, IProduct, ITag} from '@bri/rotimpres-core';
import {useTranslation} from 'react-i18next';
import {RouteProp, StackActions, useNavigation, useRoute} from '@react-navigation/native';
import {DynamicText, TOAST_STATUS, TOAST_VARIANT, loggedUser, useSharedToast, useUserService} from '@bri/shared-components';
import {useRecoilValue} from 'recoil';
import {Dimensions, Linking} from 'react-native';
import {getResource, Images} from '../utils/imagesUtils';
import {useProductsService} from '../services/ProductsService';
import {PopUpContext} from '../components/PopUpContext';
import {CertificatesDropdown} from '../components/CertificatesDropdown';
import Config from '../config/config';
import {getMultilanguageTranslation} from '../utils/languageUtils';
import {useTransfersService} from '../services/TransferService';

type Props = {
  product?: IProduct;
  tag?: ITag;
  mine: boolean;
  valid?: boolean;
  hasOwner?: boolean;
  client?: string;
};

export const ProductCard: FC<Props> = ({product, mine, tag, valid = true, hasOwner, client}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const navigation = useNavigation<any>();
  const user = useRecoilValue(loggedUser);
  const productsService = useProductsService();
  const usersService = useUserService();
  const transfersService = useTransfersService();
  const [certificatesOpen, setCertificatesOpen] = useState(false);
  const popup = useContext(PopUpContext);
  const screenWidth = Dimensions.get('window').width;
  const screenHeight = Dimensions.get('window').height;
  const propertyOfUser = tag?.owner === user?._id;
  const sharedToast = useSharedToast();

  type RouteParams = {
    transfer?: string;
  };
  const route = useRoute<RouteProp<Record<string, RouteParams>, string>>(); // Proporciona el tipo de tus parámetros de ruta aquí
  const transfer = route.params?.transfer;

  useEffect(() => {
    const handleInvalidNFC = async () => {
      if (!valid) {
        navigation.setOptions({headerShown: false});
      }

      const openEmailLink = () => {
        Linking.openURL(`mailto:${Config.supportEmail.email}`);
      };

      const buttonsNoLog = [
        {
          label: t('Log In'),
          onPress: () => {
            navigation.navigate('Login', {tagId: String(tag?._id)});
            popup?.hidePopUp();
          },
          variant: 'outline',
        },
        {
          label: t('Register'),
          onPress: () => {
            navigation.navigate('Register', {tagId: String(tag?._id)});
            popup?.hidePopUp();
          },
        },
      ];
      const buttonsLog = [
        {
          label: t('Go Home'),
          onPress: () => {
            navigation.dispatch(StackActions.replace('App', {screen: 'ProductListScreen'}));
            navigation.navigate('App', {screen: 'ProductListScreen'});
            popup?.hidePopUp();
          },
        },
      ];

      const buttonTransfer = [
        {
          label: t('Cancel'),
          onPress: () => {
            popup?.hidePopUp();
          },
          variant: 'outline',
        },
        {
          label: t('Request'),
          onPress: () => {
            navigation.navigate('App', {screen: 'ProductListScreen'});
            requestTransfer();
          },
        },
      ];

      const contentInvalid = (
        <Text>
          {t('El NFC escaneado no está vinculado a ningún producto o promoción.\n\n Para cualquier duda contacta a ')}
          <Text onPress={() => openEmailLink()} style={{color: theme.colors.primary[500]}}>
            {Config.supportEmail.email}
          </Text>
        </Text>
      );

      const contentTransferLog = (
        <>
          <Text>{t(`The registered token already belongs to another user. Do you want to send a transfer request?\n\n`)}</Text>
          <Text fontStyle="italic" textAlign="justify">
            {t('By claiming this product, I hereby give my explicit consent to share personal information with {{ client }}.', {client})}
          </Text>
        </>
      );

      const contentTransferNoLog = (
        <>
          <Text>{t('The registered token already belongs to another user. Register or log in to your account to add the token to your wallet.\n\n')}</Text>
          <Text fontStyle="italic" textAlign="justify">
            {t(`By claiming this product, I hereby give my explicit consent to share personal information with {{ client }}.`, {client})}
          </Text>
        </>
      );

      let buttons;
      let content;
      let title;
      let isError = false;

      if (user) {
        if (hasOwner) {
          title = t('This product is already owned by someone');
          buttons = buttonTransfer;
          content = contentTransferLog;
        } else {
          title = t('Invalid NFC');
          buttons = buttonsLog;
          content = contentInvalid;
          isError = true;
        }
      } else {
        buttons = buttonsNoLog;
        if (hasOwner) {
          title = t('This product is already owned by someone');
          content = contentTransferNoLog;
        } else {
          title = t('Invalid NFC');
          content = contentInvalid;
          isError = true;
        }
      }

      popup?.showPopUp(title, content, buttons, false, isError);
    };

    if (!valid || (hasOwner && !propertyOfUser)) {
      handleInvalidNFC();
    } else {
      popup?.hidePopUp();
      navigation.setOptions({headerShown: true});
    }
  }, [valid, hasOwner]);

  function transferCurrentTag() {
    productsService
      .transferTagToUser({tagId: String(tag?._id)})
      .apiResponse(() => {
        // if necessary do something here
      })
      .response(async resp => {
        navigation.navigate('App', {screen: 'ProductListScreen'});
      })
      .error(err => console.log(err));
    // .finally(() => console.log(err));
  }

  function requestTransfer() {
    productsService
      .requestTransfer({tagId: String(tag?._id), newOwnerId: String(user?._id)})
      .apiResponse(() => {
        popup?.hidePopUp();
      })
      .response(async resp => {
        if (resp.success === false)
          sharedToast({
            title: t('Wait'),
            description: t(`Wait a time before making a request again`),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        else {
          sharedToast({
            title: t('Petition successfully submitted'),
            description: t('Wait for a reply'),
            status: TOAST_STATUS.SUCCESS,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
        navigation.navigate('App', {screen: 'ProductListScreen'});
      })
      .error(err => console.log(err));
    // .finally(() => console.log(err));
  }

  function acceptTransfer() {
    popup?.showPopUp(t('Transfer NFT'), t('Are you sure you want to accept the transfer request?'), [
      {
        label: t('Cancel'),
        onPress: () => {
          popup?.hidePopUp();
        },
        variant: 'outline',
      },
      {
        label: t('Accept'),
        onPress: () => {
          transfersService
            .accept({id: String(transfer)})
            .apiResponse(() => {
              popup?.hidePopUp();
              navigation.dispatch(StackActions.replace('App', {screen: 'ProductListScreen'}));
              navigation.navigate('App', {screen: 'ProductListScreen'});
            })
            .response(async resp => {
              if (resp.success === false)
                sharedToast({
                  title: t('Wait'),
                  description: t(`Something has gone wrong`),
                  status: TOAST_STATUS.ERROR,
                  variant: TOAST_VARIANT.SUBTLE,
                });
              else {
                sharedToast({
                  title: t('Petition successfully submitted'),
                  description: t('Transfer completed'),
                  status: TOAST_STATUS.SUCCESS,
                  variant: TOAST_VARIANT.SUBTLE,
                });
              }
              navigation.navigate('App', {screen: 'ProductListScreen'});
            })
            .error(err => console.log(err));
          // .finally(() => console.log(err));
        },
      },
    ]);
  }

  function rejectTransfer() {
    popup?.showPopUp(t('Reject transfer NFT'), t('Are you sure you want to reject the transfer request?'), [
      {
        label: t('Cancel'),
        onPress: () => {
          popup?.hidePopUp();
        },
        variant: 'outline',
      },
      {
        label: t('Accept'),
        onPress: () => {
          transfersService
            .reject({id: String(transfer)})
            .apiResponse(() => {
              popup?.hidePopUp();
              navigation.dispatch(StackActions.replace('App', {screen: 'ProductListScreen'}));
              navigation.navigate('App', {screen: 'ProductListScreen'});
            })
            .response(async resp => {
              if (resp.success === false)
                sharedToast({
                  title: t('Wait'),
                  description: t(`Something has gone wrong`),
                  status: TOAST_STATUS.ERROR,
                  variant: TOAST_VARIANT.SUBTLE,
                });
              else {
                sharedToast({
                  title: t('Response sent successfully'),
                  description: t('Transfer rejected'),
                  status: TOAST_STATUS.SUCCESS,
                  variant: TOAST_VARIANT.SUBTLE,
                });
              }
            })
            .error(err => console.log(err));
          // .finally(() => console.log(err));
        },
      },
    ]);
  }

  const expirationDate = tag?.expirationDate;
  let parsedExpirationDate;
  if (expirationDate) parsedExpirationDate = new Date(expirationDate);

  const productImage = product?.image ? product?.image : Images.product;

  return (
    <Card style={{backgroundColor: theme.colors.secondary['500'], borderRadius: valid ? 12 : 0, margin: valid ? 15 : 0, padding: 0}}>
      {valid ? (
        <>
          <Image height={mine ? 262 : 190} resizeMode="cover" source={getResource(productImage)} />
          <VStack p={4}>
            <HStack space={2}>
              <VStack flex={1}>
                <Heading fontWeight="500" fontSize="2xl" lineHeight="xs" bold={!mine}>
                  {mine ? getMultilanguageTranslation(product?.name) : t('NFC Validated')}
                </Heading>
                <VStack space={2} mt={2} mb={0}>
                  {!mine && (
                    <HStack mt={5}>
                      <Text fontWeight="bold" bold>
                        {getMultilanguageTranslation(product?.name)}
                      </Text>
                    </HStack>
                  )}
                  <HStack>
                    <Text fontWeight="bold">{t('Batch')}:</Text>
                    <Text>{t(` ${product?.batch}`)}</Text>
                  </HStack>
                  <>
                    <HStack>
                      <Text fontWeight="bold">{t('Units Available')}:</Text>
                      <Text>{t(` ${product?.offers?.eligibleQuantity?.value}`)}</Text>
                    </HStack>
                    {mine && (
                      <HStack>
                        <Text fontWeight="bold">{t('Expiration Date')}:</Text>
                        <Text>{t(` ${parsedExpirationDate ? parsedExpirationDate.toLocaleDateString() : `--------`}`)}</Text>
                      </HStack>
                    )}
                  </>
                </VStack>
              </VStack>
              {!mine && (
                <VStack>
                  <Image height={115} width={115} resizeMode="cover" source={getResource(Images.nfc_ok)} />
                </VStack>
              )}
            </HStack>
            <VStack pt={mine ? 2 : 0}>
              <Text fontWeight="bold">{t('Description')}: </Text>
              <DynamicText content={getMultilanguageTranslation(product?.description)} maxLines={4} justify />
            </VStack>

            <Box mt={2} mb={8}>
              <HStack space={2} justifyContent="center">
                {transfer ? (
                  <HStack space={2} flex={1}>
                    <Button
                      flex={1}
                      shadow={2}
                      alignSelf="center"
                      bgColor="error.500"
                      _text={{color: 'white'}}
                      onPress={() => {
                        rejectTransfer();
                      }}>
                      {t('Reject')}
                    </Button>
                    <Button
                      flex={1}
                      shadow={2}
                      alignSelf="center"
                      onPress={() => {
                        acceptTransfer();
                      }}>
                      {t('Transfer')}
                    </Button>
                  </HStack>
                ) : (
                  <>
                    {product != undefined && product.certificates.length > 0 && (
                      <Button
                        flexGrow={1}
                        alignSelf="left"
                        _text={{fontWeight: 'bold', color: 'lightBlue.900'}}
                        onPress={() => {
                          if (product?.certificates.length === 1) {
                            window.open((product?.certificates[0] as ICertificate).info, '_blank');
                          } else {
                            setCertificatesOpen(!certificatesOpen);
                          }
                        }}
                        backgroundColor="none"
                        borderColor="lightBlue.900"
                        borderWidth={1.5}>
                        <Text color="lightBlue.900">
                          {' '}
                          {t('Certificates')} {certificatesOpen ? <img src={Images.arrowUp} /> : <img src={Images.arrowDown} />}
                        </Text>
                      </Button>
                    )}

                    {tag != undefined && tag.returnPoints.length > 0 && (
                      <Button
                        flexGrow={1}
                        onPress={() => {
                          navigation.navigate('DevolutionMap', {tag});
                        }}>
                        {t('Devolution Map')}
                      </Button>
                    )}
                  </>
                )}
              </HStack>
              <CertificatesDropdown certificates={product?.certificates as ICertificate[]} open={certificatesOpen} />
            </Box>

            {!hasOwner && !user && (
              <>
                <VStack space={4}>
                  <Text textAlign="justify">
                    {t(`By claiming this product, I hereby give my explicit consent to share personal information with {{ client }}.`, {client})}
                  </Text>
                  <Button
                    marginTop={3}
                    w="fit-content"
                    alignSelf="center"
                    px={6}
                    _text={{fontWeight: 'light'}}
                    onPress={() => {
                      popup?.showPopUp(t(' Register to claim this NFT'), t('Claiming this product with your user will assign this NFT ownership to you exclusively.'), [
                        {
                          label: t('Log In'),
                          onPress: () => {
                            navigation.navigate('Login', {tagId: String(tag?._id)});
                            popup?.hidePopUp();
                          },
                          variant: 'outline',
                        },
                        {
                          label: t('Register'),
                          onPress: () => {
                            navigation.navigate('Register', {tagId: String(tag?._id)});
                            popup?.hidePopUp();
                          },
                        },
                      ]);
                    }}>
                    {t('Register and get the promotions!')}
                  </Button>
                </VStack>
              </>
            )}

            {!hasOwner && !propertyOfUser && user && (
              <>
                <VStack space={4}>
                  <Text fontStyle="italic" textAlign="justify">
                    {t('By claiming this product, I hereby give my explicit consent to share personal information with {{ client }}.', {client})}
                  </Text>
                  <Button
                    w="fit-content"
                    alignSelf="center"
                    px={6}
                    _text={{fontWeight: 'light'}}
                    onPress={() => {
                      transferCurrentTag();
                    }}>
                    {t('Claim and get the promotions!')}
                  </Button>
                </VStack>
              </>
            )}
          </VStack>
        </>
      ) : (
        <>
          <Image style={{width: screenWidth, height: screenHeight}} resizeMode="cover" source={require('../images/rot_blurredOut.png')} />
        </>
      )}
    </Card>
  );
};
