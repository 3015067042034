import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useEffect, useState} from 'react';
import {CLIENT_ERROR_CODES_ROTIMPRES, IProduct, ITag} from '@bri/rotimpres-core';
import {Heading, ScrollView, Spinner, Text, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';
import {StackActions, useNavigation} from '@react-navigation/native';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {ScreensParams} from '../navigators/screens';
import {ProductCard} from '../components/ProductCard';
import {ItemList} from '../components/ItemList';
import {useProductsService} from '../services/ProductsService';
import {initialTagRecoil} from '../recoil/atoms/InitialTagAtom';
import {ItemInfo, ItemType} from '../components/Item';

type Props = BottomTabScreenProps<ScreensParams, 'Product'>;

export const ProductScreen: FC<Props> = props => {
  const productsService = useProductsService();
  const [tag, setTag] = useState<ITag>();
  const [product, setProduct] = useState<IProduct>();
  const [initialTag, setInitialTag] = useRecoilState(initialTagRecoil);
  const navigation = useNavigation<any>();
  const [client, setClient] = useState('');
  const tagId = props.route.params && props.route.params.id;
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const sharedToast = useSharedToast();
  let promotionsList: ItemInfo[] = [];

  if (initialTag && tagId !== initialTag) {
    navigation.setParams({id: initialTag});
    setInitialTag(null);
  }

  useEffect(() => {
    if (tagId) {
      productsService
        .getByTag({id: String(tagId)})
        .apiResponse(() => {
          setLoading(false);
        })
        .response(async resp => {
          setTag(resp.tag);
          setProduct(resp.product);
          setClient(resp.client);
        })
        .error(err => {
          if (err.code === CLIENT_ERROR_CODES_ROTIMPRES.TAG_EXPIRED) {
            sharedToast({
              title: t('Product expired'),
              description: t('The product you are trying to view has already expired'),
              status: TOAST_STATUS.ERROR,
              variant: TOAST_VARIANT.SOLID,
            });

            navigation.dispatch(StackActions.replace('App', {screen: 'ProductListScreen'}));
            navigation.navigate('App', {screen: 'ProductListScreen'});
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [tagId]);

  if (loading) {
    return <Spinner size="lg" mt={3} />;
  }

  if (!tag || !product) {
    return <Text>{t('Product ID Invalid')}</Text>;
  }

  if (tag) {
    promotionsList = tag.promotions.map((promotion: any) => ({
      id: promotion._id,
      name: promotion.name,
      expiration_date: promotion.expiration_date,
      description: promotion?.description,
      image: promotion.image,
      promotionCode: promotion?.promotionCode,
      client,
      type: ItemType.Promotion,
    }));
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View mt={5}>
        {/* <Image height={35} resizeMode="cover" source={getResource(Images.background)} mb={2} /> */}
        {/* <Heading fontSize="4xl" alignSelf="center">
          {client}
        </Heading> */}
        <ProductCard client={client} product={product} mine tag={tag} />
        {Array.isArray(tag?.promotions) && tag.promotions.length > 0 && (
          <ItemList title={t('Promotions included') as string} data={promotionsList} type={ItemType.Promotion} />
        )}
      </View>
    </ScrollView>
  );
};
