import {BottomTabScreenProps} from '@react-navigation/bottom-tabs';
import React, {FC, useEffect, useState} from 'react';
import {IProduct, ITag} from '@bri/rotimpres-core';
import {Heading, ScrollView, Spinner, Text, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {ScreensParams} from '../navigators/screens';
import {ProductCard} from '../components/ProductCard';
import {ItemList} from '../components/ItemList';
import {useProductsService} from '../services/ProductsService';
import {ItemInfo, ItemType} from '../components/Item';
// import {getLinkingSchema} from '../navigators/linking';

type Props = BottomTabScreenProps<ScreensParams, 'PreviewProduct'>;

export const PreviewProductScreen: FC<Props> = props => {
  const productsService = useProductsService();
  const [tag, setTag] = useState<ITag>();
  const [product, setProduct] = useState<IProduct>();
  const [client, setClient] = useState('');
  const [otherUserOwner, setOtherUserOwner] = useState<string | undefined>(undefined);
  const tagCode = props.route.params && props.route.params.id;
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const navigation = useNavigation<any>();
  let promotionsList: ItemInfo[] = [];

  useEffect(() => {
    if (tagCode) {
      productsService
        .getPreview({id: tagCode})
        .apiResponse(() => {
          setLoading(false);
        })
        .response(async resp => {
          setTag(resp.tag);
          setProduct(resp.product);
          setClient(resp.client);
        })
        .error(err => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [tagCode]);

  if (loading) {
    return <Spinner size="lg" mt={3} />;
  }
  if (!product) {
    return <ProductCard client={client} product={product} mine={false} valid={false} tag={tag} />;
  }

  if (!tag || !product) {
    return <Text>{t('Product ID Invalid')}</Text>;
  }

  if (tag) {
    promotionsList = tag.promotions.map((promotion: any) => ({
      id: promotion._id,
      name: promotion.name,
      expiration_date: promotion.expiration_date,
      description: promotion?.description,
      image: promotion.image,
      promotionCode: promotion?.promotionCode,
      client,
      type: ItemType.Promotion,
    }));
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <View mt={5}>
        <Heading fontSize="4xl" alignSelf="center">
          {client}
        </Heading>
        <ProductCard client={client} product={product} mine tag={tag} />
        {Array.isArray(tag?.promotions) && tag.promotions.length > 0 && (
          <ItemList title={t('Promotions included') as string} data={tag?.promotions as any} type={ItemType.Promotion} />
        )}
      </View>
    </ScrollView>
  );
};
